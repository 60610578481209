/* compileOption={"opt":"-b -s 1"} */
(function() {
	window.KW = window.KW || {};
	KW.Web = new Web();

	/*
	 *
	 *
	 * Web
	 */
	function Web() {
		var settings;
		var tempJ,
		    spinnerJ;
		var isEn;
		var routeId;

		this.init = _init;
		this.showContainer = _showContainer;
		this.setLoading = _setLoading;
		this.loadScripts = _loadScripts;
		this.loadCsses = _loadCsses;
		this.generateContentD = _generateContentD;
		this.translate = _translate;
		this.scrollTo = _scrollTo;
		this.getTempJ = _getTempJ;
		this.getRouteId = _getRouteId;
		this.isEnglish = _isEnglish;
		this.setCookie = _setCookie;
		this.getCookie = _getCookie;
		this.parseJson = _parseJson;
		this.utf8ToBase64 = _utf8ToBase64;
		this.base64ToUtf8 = _base64ToUtf8;
		this.getQuery = _getQuery;
		this.getServerDate = _getServerDate;

		function _init(cfg/*{css, script, template}*/) {
			settings = cfg;

			$.ajaxSetup({
				cache : false
			});

			if ($("body > .container").length == 0) {
				$("<div class='container'></div>").appendTo($("body"));
				console.log(".container added automatically!");
			}
			$("body > .container").css("display", "none");

			var s = location.href;
			var m;
			if ( m = s.match(/route=([^&|^$]+)/)) {
				routeId = m[1];
			} else {
				if (/^(.*?)(index.html|\/)($|\?|#)/.test(location.href)) {
					routeId = "index";
				} else if ( m = s.match(/\/([^\/]+)\.html(\?|$|#)/)) {
					routeId = m[1];
				} else {
					alert(m);
					alert("invalid url!");
				}
			}

			//=== ローディングないほうが直帰率低い説（怖がらない？）
			// _setLoading(true);

			var m;
			var lang = ( m = location.href.match(/lang=(\w\w)/)) ? m[1]//
			: _getCookie("kw_active_lang")//
			|| (navigator.language || navigator.userLanguage).substr(0, 2).toLowerCase();

			_setCookie("kw_active_lang", lang, 365);

			isEn = lang != "ja";

			cfg.css && _loadCsses(cfg.css);

			return cfg.script ? _loadScripts(cfg.script) : $.Deferred().resolve();
		}

		function _showContainer() {
			$("body > .container").css("display", "");
		}

		function _setLoading(f) {
			spinnerJ = spinnerJ || $('<div class="loading_spinner"><div class="spin"></div></div>');
			if (f) {
				if (spinnerJ.parent().length == 0) {
					spinnerJ.appendTo($("body"));
				}
			} else {
				spinnerJ.remove();
			}
		}

		function _loadScripts(urls) {
			var urls2 = JSON.parse(JSON.stringify(urls));
			var dfd = $.Deferred();
			_0();
			return dfd;

			function _0() {
				if (urls2.length) {
					var url = urls2[0];
					urls2.splice(0, 1);
					_1(url).done(function() {
						_0();
					}).fail(function() {
						alert("Script not loaded!\n" + url);
						dfd.reject();
					});
				} else {
					dfd.resolve();
				}
			}

			function _1(url) {
				return $.Deferred(function(dfd2) {
					var sc = document.createElement('script');
					sc.type = 'text/javascript';
					sc.onload = function() {
						dfd2.resolve();
					};
					sc.onerror = function() {
						dfd2.reject();
					};
					sc.src = url;
					var sc2 = document.getElementsByTagName( 'script' )[0];
					sc2.parentNode.insertBefore(sc, sc2);
				});
			}

		}

		function _loadCsses(urls) {
			$.each(urls, function(i, url) {
				var elem = document.createElement('link');
				elem.type = 'text/css';
				elem.rel = 'stylesheet';
				elem.href = url;
				var sc = document.getElementsByTagName( 'script' )[0];
				sc.parentNode.insertBefore(elem, sc);
			});
		}

		function _generateContentD(cfg/*{templateHtml, [contentId, ]contentDefs, divsOrder, onContentReady}*/) {
			var cId = cfg.contentId || routeId;
			var cDef = cfg.contentDefs[cId];

			if (!cDef)
				return $.Deferred().reject("ERROR");

			var divsOrder = cfg.divsOrder;
			var template = cDef.template || settings.template;

			return $.get(template).then(function(html) {
				tempJ = $(html);

				var contJ = $("<div></div>");

				document.title = (!isEn || !cDef.titleEn) ? cDef.title : cDef.titleEn;

				$.each(divsOrder, function(i, val) {
					if (cDef.divs[val]) {
						var areaJ = $('<div id="%id%"></div>'.replace("%id%", val));
						for (var i = 0; i < cDef.divs[val].length; i++) {
							var tmplInfo = cDef.divs[val][i];
							var tmplJ = tempJ.find("#" + tmplInfo[0]).clone();
							tmplJ = (tmplJ.length > 0) ? tmplJ : $("<div></div>");
							tmplInfo[1] && tmplInfo[1](tmplJ, tmplInfo.length >= 3 ? tmplInfo[2] : null);
							tmplJ.appendTo(areaJ);
						}
						areaJ.appendTo(contJ);
					}
				});

				//=== post render tesks
				contJ.find("a[href*=lang_id]").each(function(i, elem) {
					elem.href = elem.href.replace("{{lang_id}}", isEn ? "en" : "ja");
				});
				$("html").attr("lang", isEn ? "en" : "ja");
				_translate(contJ);
				$("body").addClass( isEn ? "en" : "ja");

				cfg.onContentReady && cfg.onContentReady(contJ);

				contJ.each(function(i, elem) {
					$("body > .container").append($(elem));

					//=== ローカルバーを設置
					if (location.href.match(/^file:\/\/|localhost\//)) {
						$("body").prepend('<div id="local_bar">Local</div>');
					}
				});
			});
		}

		function _translate(j) {
			j.find("[kw_lang]").each(function(i, elem) {
				if (isEn && elem.tagName.toLowerCase() == "img") {
					elem.src = elem.src.replace(/\.png/, "-en.png");
				} else {
					var j = $(elem);
					var m;
					if ( m = j.html().match(/^([\s\S]*)\/en:([\s\S]*)$/)) {
						$(elem).html( isEn ? m[2] : m[1]);
					} else {
						isEn && $(elem).html("<span style='color:red;font-weight:bold'>Translate</span>" + $(elem).html());
					}
				}
			});
		}

		function _scrollTo(toJ) {
			var targetOffset = toJ.length ? toJ.offset().top : 0;
			$('html,body').animate({
				scrollTop : targetOffset - 60
			}, 500);
		}

		function _getTempJ() {
			return tempJ;
		}

		function _getRouteId() {
			return routeId;
		}

		function _isEnglish() {
			return isEn;
		}

		function _setCookie(name, value, days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				var expires = "; expires=" + date.toGMTString();
			}
			document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
		}

		function _getCookie(name) {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ')
				c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0) {
					return decodeURIComponent(c.substring(nameEQ.length, c.length));
				}
			}
			return "";
		}

		function _parseJson(txt) {
			try {
				return JSON.parse(txt);
			} catch(e) {
				return null;
			}
		}

		function _utf8ToBase64(s) {
			return window.btoa(unescape(encodeURIComponent(s)));
		}

		function _base64ToUtf8(s) {
			return decodeURIComponent(escape(window.atob(s)));
		}

		function _getQuery(name) {
			name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
			var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
			    results = regex.exec(location.search);
			return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
		}

		function _getServerDate() {
			var dfd = $.Deferred();
			$.ajax({
				type : 'GET',
				url : '',
				cache : false
			}).then(function(res, status, xhr) {
				var date;
				try {
					date = new Date(xhr.getResponseHeader('Date'));
				} catch (_error) {
					date = new Date();
				}
				dfd.resolve(date);
			}, function(err) {
				alert("Web.getServerDate() failed! use local date!");
				dfd.resolve(new Date());
			});
			return dfd.promise();
		}

	}

})();
